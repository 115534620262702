<template>
  <div class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Contas a pagar
        <span id="help-icon" class="icon-box">
          <v-help-circle class="icon stroke no-print" />
        </span>

        <b-tooltip target="help-icon" placement="bottom">
          Crie e gerencie contas a pagar, visualize datas, categorias,
          descrições e valores. Selecione as contas que deseja e clique no botão
          "nova saída" para gerar nova conta a pagar.
        </b-tooltip>
      </div>

      <div class="gap-action-buttons no-print">
        <Loading :class="{ reload: true, loading: loading }" @click="() => !loading && getBillsToPay()"/>
        <b-button
          v-if="selected.length"
          @click="selectedPay"
          class="wh-button"
          variant="outline-primary"
          outline
        >
          Pagar selecionado(s)
        </b-button>

        <b-button
          class="wh-button"
          variant="outline-primary"
          outline
          @click="getXlsx"
        >
          Download xlsx
        </b-button>
      </div>
    </div>

    <div class="row no-print">
      <div class="col-3">
        <b-form-group class="form-group">
          <label for="search">Descrição da conta</label>
          <b-form-input
            autocomplete="off"
            id="search"
            debounce="500"
            v-model="query"
            placeholder="Buscar por descrição"
          />
        </b-form-group>
      </div>

		<div class="col-2">
			<b-form-group class="form-group">
				<label for="search">Conta recorrente</label>
				<v-checkbox-select
					id="cost-center"
					optionLabel="label"
					trackBy="value"
					:value="type_recurrence"
					:options="type_recurrences"
					@onChange="changeSelectedTypeRecurrence"
				/>
			</b-form-group>
		</div>

      <div class="col-2">
        <b-form-group id="field_category">
          <label for="field_category">Categoria</label>
          <v-autocomplete
            v-model="category"
            :options="categories"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Situação</label>
          <v-autocomplete
            v-model="status_payment"
            :options="status_payments"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group class="form-group">
          <div class="label">
            <label for="search" id="input-title">Período</label>

            <div class="status">
              
              <div class="status-input">
                <input
                  type="radio"
                  name="status"
                  value="due_date"
                  v-model="periodStatus"
                />Venc.
              </div>

              <div class="status-input">
                <input
                  type="radio"
                  name="status"
                  value="created_at"
                  v-model="periodStatus"
                />Criac.
              </div>

              <div class="status-input">
                <input
                  type="radio"
                  name="status"
                  value="payment_date"
                  v-model="periodStatus"
                />Pagam.
              </div>

              <div class="status-input">
                <SwapVertical
                  class="order-icon"
                  @click="periodOrder === 'desc' ? (periodOrder = 'asc') : (periodOrder = 'desc')"
                />
              </div>
            </div>
          </div>

          <v-periods
            :daySelected="daySelected"
            @select="handlePeriod"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row no-print">
      <div class="col-3">
        <v-beneficiaries-select
          v-model="beneficiary"
          :value="beneficiary"
          label="Favorecido"
        />
      </div>

      <div class="col-3">
        <v-patient-input v-model="patient" />
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Centro de custo</label>
          <v-checkbox-select
            id="cost-center"
            optionLabel="label"
            trackBy="value"
            :value="selectedCostCenters"
            :options="costCenterOptions"
            v-on:onChange="changeSelectedCostCenters"
            :key="selectedCostCenters.length"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Pagamento</label>
          <v-autocomplete
            v-model="payment_method"
            :options="payment_methods"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group id="field_bank_account">
          <label for="field_bank_account">Conta bancária</label>
          <v-autocomplete
            v-model="bank_account"
            :options="bank_accounts"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>
    </div>

    <v-bills-table
      :bills="billsToPay"
      :onEdit="onEditBillsToPay"
      type="bills_to_pay"
      :getBills="getBillsToPay"
      @selected-bills="selectedBills"
    />

    <div class="amount-position">
        <div>
          <p>Valor total <span>R${{ parseNumberToMoney(sumAllInstallmentAmount).trim().split('$')[1]}} </span></p>
        </div>
        <div>
          <p>Valor pág. {{this.page}} de {{this.last_page}}
            <span>
              R${{ parseNumberToMoney(getTotalAMount()).trim().split('$')[1]}}
            </span>
          </p>
        </div>
      </div>

    <div class="pagination-position no-print">
      <div>
          <b-button
            v-if="selected.length"
            class="wh-button"
            variant="outline-primary"
            outline
            @click="selectedPay"
          >
            Pagar selecionado(s)
          </b-button>
      </div>

      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>

    <v-selected-pay-modal
      identifier="selected-pay-modal"
      :installments="selected"
      :getBillsToPay="getBillsToPay"
      @onHide="onHideSelectedPayModal"
    />
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { EventBus } from '@/utils/eventBus'
import { saveAs } from 'file-saver';
import moment from 'moment'

export default {
  name: 'bills-to-pay-tab',
  components: {
    Loading: () => import('@/assets/icons/loading.svg'),
    SwapVertical: () => import('@/assets/icons/swap-vertical.svg'),
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    'v-checkbox-select': () => import('@/components/CheckboxSelect.vue'),
    'v-autocomplete': () => import('@/components/Autocomplete'),
    'v-periods': () => import('@/components/General/Periods'),
    'v-patient-input': () => import('@/components/General/PatientInput'),
    'v-beneficiaries-select': () => import('@/components/General/BeneficiarySelect'),
    'v-bills-table': () => import('@/modules/financial/components/BillsTable'),
    'v-selected-pay-modal': () => import('@/modules/financial/modals/BillsToPaySelectedPayModal'),
  },
  props: {
    onEditBillsToPay: Function,
  },
  async mounted() {
    if (this.$route.query.v === 'v') {
      this.expirationType = 'today';
    }
    if (this.$route.query.p === 'h') {
      const now = { start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') };
      await this.handlePeriod(now)
      this.daySelected = true
    }

    await this.getBillsToPay()
    await this.getCategories()
    await this.getPaymentsMethods()
    await this.getBankAccounts()
    await this.getCostCenters()
    EventBus.$on('reloadBillsToPay', async () => await this.getBillsToPay(true));
  },

  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      loading: false,
      selected: [],
      period: null,
      periodStatus: 'due_date',
      periodOrder: 'asc',
      query: '',
      page: 1,
      limit: 0,
      count: 0,
      clinicId: clinic.id,
      installments: [],
      billsToPay: [],
      category: null,
      categories: [],
      status_payment: null,
      start: null,
      end: null,
      expirationType: null,
      beneficiary: null,
      professional: null,
      professionals: [],
      patient: null,
      patients: [],
      payment_method: null,
      payment_methods: [],
      bank_account: null,
      bank_accounts: [],
      sumInstallmentAmountOfPage: 0,
      sumAllInstallmentAmount: 0,
      last_page: 0,
	  type_recurrence: [],
	  type_recurrences: [
	  	{value: 'recurly_month', label: 'Recorrência mensal'},
	  	{value: 'recurly_year', label: 'Recorrência anual'},
	  	{value: 'rating_fixed', label: 'Custo fixo'},
	  	{value: 'rating_variable', label: 'Custo variável'},
	  ],

      status_payments: [
        { label: 'Vencido', value: 'expired' },
        { label: 'Pago', value: 'paid_out' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Parcelado', value: 'installments' },
      ],

      costCenters: null,
      selectedCostCenters: [],
      costCenterOptions: [],
      daySelected: false,
    }
  },

  methods: {
    parseNumberToMoney,
    selectedBills(billsToPay, billsToReceive) {     
      if (!this.selected?.length) {
        this.selected = billsToPay || []
        return
      }
      const aux = this.selected.concat(billsToPay)
      this.selected = aux.filter((item, index) => aux.indexOf(item) === index)
      console.log('billsToPay', aux, billsToPay, this.selected)
    },
    selectedPay() {
      this.$bvModal.show('selected-pay-modal')
    },

    async getBillsToPay () {
      this.loading = true
      try {
        const filters = {
          type: 'bill_to_pay',
          description: this.query?.length ? this.query : null,
          category: this.category,
          status_payment: this.status_payment,
          start: this.start,
          end: this.end,
          beneficiary: this.beneficiary?.id,
          professional: this.professional?.id,
          patient: this.patient?.id,
          payment_method: this.payment_method,
          bank_account: this.bank_account,
          periodStatus: this.periodStatus,
          periodOrder: this.periodOrder,
          costCenters: this.costCenters,
		      type_recurrence: this.type_recurrence,
        }

        const res = await this.api.getClinicBills(this.clinicId, this.page, filters)

        this.last_page = res.data.last_page
        this.billsToPay = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
        this.sumAllInstallmentAmount = res.data.sum

      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    },

    async getCategories() {
      try {
        const response = await this.api.getCategories(this.clinicId, 'Saída', null, true);
        this.categories = response.data.map(category => ({
          ...category,
          label: `${category.name}`,
          value: `${category.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getProfessionals() {
      try {
        const response = await this.api.showClinicProfessionals(this.clinicId)
        this.professionals = response.data.map(professional => ({
          ...professional,
          label: `${professional.name}`,
          value: `${professional.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getPaymentsMethods() {
      try {
        const response = await this.api.getPaymentMethodsBillToReceive(
          this.clinicId
        )
        this.payment_methods = response.data.map(payment => ({
          ...payment,
          label: `${payment.name}`,
          value: `${payment.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getBankAccounts() {
      try {
        const response = await this.api.getBankAccountBillToReceive(
          this.clinicId
        )
        this.bank_accounts = response.data.map(bank => ({
          ...bank,
          label: `${bank.name}`,
          value: `${bank.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    getTotalAMount() {
      return this.billsToPay.reduce((acc, bill) => {
        return acc + bill?.payments.reduce((el, payment) => {
          return el + payment?.amount
        }, 0)
      }, 0)
    },

    changeSelectedCostCenters(value){
      this.costCenters = value?.map( costCenter => (costCenter.value))
    },

	changeSelectedTypeRecurrence(value) {
	  this.type_recurrence = value?.map(item => (item.value))
	},

    getCostCenters() {
      this.api.getCostCenters(this.clinicId)
      .then(res => {
          this.costCenterOptions = [{
            value: null,
            label: 'Todos',
          }]

          res.data.map((center) => {
            if(center.active){
              this.costCenterOptions.push({
                value: center.id,
                label: center.name,
              })
            }
          })

          this.selectedCostCenters = this.costCenterOptions.map(el => el)
        })
      .catch(err => { this.$toast.error(err.message) })
    },

    handlePeriod(value) {
      this.start = value.start
      this.end = value.end
    },

    onHideSelectedPayModal() {
      this.selected = [];
      this.getBillsToPay(true);
    },

    getXlsx() {
      const isLoading = this.$loading.show();
      this.api.getBillToPayExport(
        this.clinicId,
        {
          description: this.query?.length ? this.query : null,
          category: this.category,
          status_payment: this.status_payment,
          start: this.start,
          end: this.end,
          beneficiary: this.beneficiary?.id,
          professional: this.professional?.id,
          patient: this.patient?.id,
          payment_method: this.payment_method,
          bank_account: this.bank_account,
          periodStatus: this.periodStatus,
          periodOrder: this.periodOrder,
          costCenters: this.costCenters,
        }
      )
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'contas_a_pagar.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
  },

  watch: {
    async page() {
      await this.getBillsToPay()
    },
    async query() {
      await this.getBillsToPay()
    },
    async category() {
      await this.getBillsToPay()
    },
    async status_payment() {
      await this.getBillsToPay()
    },
    async start() {
      await this.getBillsToPay()
    },
    async beneficiary() {
      await this.getBillsToPay()
    },
    async patient() {
      await this.getBillsToPay()
    },
    async payment_method() {
      await this.getBillsToPay()
    },
    async bank_account() {
      await this.getBillsToPay()
    },
    async periodStatus() {
      await this.getBillsToPay()
    },
    async periodOrder() {
      await this.getBillsToPay()
    },
    async costCenters(){
      await this.getBillsToPay()
    },

	async type_recurrence(){
	  await this.getBillsToPay()
	},
  }
}
</script>

<style scoped lang="scss">
.pt-container {
  padding: 30px 10px;

  .pt-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 30px 0;

    .gap-action-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .pt-title {
    display: inline-flex;
    align-items: center;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 18px;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }

  .pagination-position {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0 !important;
  }
  .amount-position {
    display: flex;
    flex-direction: row-reverse;

    div {
      padding: 8px 16px;
      background: var(--blue-100);
      border-radius: 8px;
      margin-left: 10px;
      justify-content: center;

      p {
        font-size: 16px;
        line-height: 150%;
        color: var(--type-active);

        span {
          font-weight: 700;
          color: var(--blue-500);
        }
      }
    }
  }

  label {
    width: 100%;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    color: var(--type-active);
    text-align: left !important;
    color: var(--dark-blue);
    margin-bottom: 4px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: minmax(auto, 24px);

    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .status-input {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }
    }
  }

  #input-title {
    font-family: 'Nunito Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: var(--type-active);
  }
}

.wh-button {
  color: var(--blue-500);
}

.order-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: var(--type-active);
}
</style>
